import React from "react"
import styled, { css } from "styled-components"
import arrow from "./arrow.png"

const header = css`
  & > h1 {
    margin: 0;
    padding-left: 1.5em;
    font-size: 2em;
    background-image: url(${arrow});
    background-position: left;
    background-repeat: no-repeat;
    background-size: 1.15em 100%;
  }
`

const line = css`
  &::before,
  &::after {
    display: block;
    content: "";
    border-top: 1px solid lightgray;
    height: 0;
    position: absolute;
    top: 0.75em;
  }

  &::before {
    width: 2em;
    left: 0;
  }

  &::after {
    width: calc(100% - 3.5em);
    right: 0;
  }
`

export const Header = styled(({ className }) => (
    <section className={className}>
        <h1>International Index on Industrial Demand-Side Flexibility Potential</h1>
        <p>
            We conducted a global analysis to identify countries with the maximum
            potential to implement flexibility technology in their industrial
            (manufacturing) sector. We built an index and ranked all countries in the
            world according to their potential for implementing industrial demand side
            management (IDSM) / industrial flexibility technology. Top countries with
            attractive conditions for industrial demand-side flexibility are shown on
            the world map below. Click on the blue colored countries to learn more
            about countries with high potential.
        </p>
        <p>
            To read more about our motivation and research approach behind this index,{" "}
            <a href="FlexMap_info.pdf" target="_blank">click here
            </a>
        </p>
    </section>
))`
  padding: 1.25em 1em 1em;
  grid-area: header;
  font-size: 0.9em;
  position: relative;

  ${header}
  ${line}
`
