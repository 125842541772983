import React from "react"
import styled from "styled-components"

export const Map = styled(({ className }) => (
    <iframe className={className} src="/content/display.html" title="map" />
))`
  border: 3px solid black;
  width: 100%;
  height: 100%;
  grid-area: map;
`
