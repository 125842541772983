import React from "react"
import styled, { css } from "styled-components"
import snt from "./snt.svg"
import bbf from "./BBF.svg"
import fim from "./fim.svg"
import fit from "./FIT.svg"
import kopernikus from "./Kopernikus.svg"
import ptj from "./PtJ.svg"


export const Footer = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <img className="item" src={snt} alt="uni.lu | snt" key="logo1" />
      <img className="item" src={kopernikus} alt="kopernikus.lu | kopernikus" key="logo6" />
      <img className="item" src={bbf} alt="bbf.lu | bbf" key="logo2" />
      <img className="item" src={ptj} alt="ptj.lu | ptj" key="logo3" />
      <img className="item" src={fit} alt="fit.lu | fit" key="logo5" />
      <img className="item" src={fim} alt="fim.lu | fim" key="logo4" />
    
    </Grid>
    
  </section>
))`
margin-left:2%;
height: 100%;

.item {
  padding-top:2%;
  height: 70%;
    width: 50%;
    margin-right:2%;
}
& > span{
  font-size: 0.75rem;
    font-family: Gudea;
    font-weight: 400;
    line-height: 2.66;
    text-transform: uppercase;
    font-color:black;
    margin-right: 3%;
}
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 2%;
  justify-items: right;
`;

