import React from "react"
import styled from "styled-components"
import { Countries } from "./countries"
import { Header } from "./header"
import { Map } from "./map"
import { Footer } from "./footer"

export const App = styled(({ className }) => (
  <main className={className}>
    <GridLayout>
          <Nav><Header /></Nav>
          <Main><Map /></Main>
          <AsideRight> <Countries /></AsideRight>
          <Footer />
          <span className="spanText" ><a href="https://synergie-projekt.de/datenschutz" target="_blank">Impressum</a></span>
    
          </GridLayout>
  </main>
))`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    "header header"
    "map countries"
    "footer footer";

  .spanText {
    display: flex;
    align-items: center;
    margin-left: 15%;
  }
`


const GridLayout = styled.div`
  height: 100vh;
  display: grid;
  grid-template-areas:
    'nav nav'
    'main asideRight'
    'footergrid footergrid';
  grid-template-rows: 1fr 20fr 1fr;
  grid-template-columns: 10fr 2fr;
`;

const Nav = styled.nav`
  grid-area: nav;
`;
const AsideRight = styled.aside`
  grid-area: asideRight;
`;
const Main = styled.main`
  grid-area: main;
`;
const FooterGrid = styled.footer`
  grid-area: footergrid;
  display: flow-root;
`;
