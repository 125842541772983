import React from "react"
import styled from "styled-components"

const countries = [
    "1. Denmark​",
    "2. Ireland​",
    "3. Germany​",
    "4. Belgium​",
    "5. Luxembourg​",
    "6. Uruguay​",
    "7. United Kingdom​",
    "8. Spain​",
    "9. Austria",
    "10. Lithuania",
    "11. Italy​",
    "12. Netherlands​",
    "13. Portugal​",
    "14. Australia​",
    "15. Sweden​",
    "16. Finland​",
    "17. Japan​",
    "18. Romania",
    "19. Chile​",
    "20. Greece​",
    "21. China​",
    "22. USA​",
    "23. France​",
    "24. Poland​",
    "25. Switzerland​",
    ".",
    ".",
    ".",
    "42. South Africa",
    ".",
    ".",
    ".",
    "51. India",
]

export const Countries = styled(({ className }) => (
    <section className={className}>
        <h2>Countries ​from our index:​</h2>
        <ol>
            {countries.map(name => (
                <li>{name}</li>
            ))}
        </ol>
    </section>
))`
  padding: 0 1.5em 53px;
  font-size: 0.9em;
  grid-area: countries;
  position: relative;

  & > h2 {
    margin-top: 0;
    font-size: 1em;
  }

  & > ol {
    padding-left: 2em;
  }

  & > ol li{
    list-style-type: none;
  }
  & > img {
    position: absolute;
    bottom: 0;
  }
`
